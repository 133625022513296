.wrapper {
    background: #edf2f8;
    /* height: 100vh; */
}

.testimonials {
    max-width: 1440px;
    margin: 100px auto;
    padding: 20px;
    display: grid;
    background: #edf2f8;

    gap: 30px;

    grid-template-columns: repeat(4, 1fr);
}

.card {
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: rgba(17px 12px 46px 0.15px) 0px 48px 100px 0px;
    margin-bottom: 10px;
}

.card__header {
    display: flex;
    align-items: center;
}

.card__header h3 {
    font-size: 15px;
}

.card__header p {
    opacity: 50%;
}

.card__img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #996ed9;
    margin-right: 10px;
}

.card__lead {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 10px;
}

.card__qoute {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4;
    opacity: 70%;
}

.card--bg--purple {
    background: hsl(263, 55%, 52%);
    color: #fff;
    /* background-image: url("./images/bg-pattern-quotation.svg"); */
    /* background-repeat: no-repeat;
    background-position: top 10px right 100px; */
}

.card--bg--gray-blue {
    background: hsl(217, 19%, 35%);
    color: #fff;
}

.card--bg-black-blue {
    background: hsl(219, 29%, 14%);
    color: #fff;
}

.card:nth-of-type(1){
    grid-column: 1 /3;
}

.card:nth-of-type(4){
    grid-column: 4;
    grid-row: 1 / 3;
    
}

.card:nth-of-type(5){
    grid-column: 2 / 4;
    grid-row: 2;
}

@media(max-width: 768px){

    /* body {
        display: none;
    } */

    .testimonials {
        grid-template-columns: 1fr;
        width: 100%;
    }

    .card:nth-of-type(1){
        grid-column: 1;
    }

    .card:nth-of-type(4){
        grid-column: 1;
        grid-row: 4;
    }
    
    .card:nth-of-type(5){
        grid-column: 1;
        grid-row: 5;
    }
}